<template>
  <main v-if="pageLoaded">
    <div v-if="jobs.length < 1" class="mt-2">
      <Empty
        button
        @doSomething="openJob"
        :title="'There are no jobs posted.'"
        :text="'Your next best candidate is waiting for you. We have thousands of applicants looking for a new journey. Post a job now and make use of our all-in-one applicant tracking system'"
      >
        <Cross class="mr-2" fill="white" />
        <router-link to="/new-job"> <p>Post a job</p></router-link>
      </Empty>
      <!-- <router-link to="/new-job"
        ><tam-button class="px-4" @click="showJob" type="submit" color="infoOpacity" label="Post a job" :icon="Cross"
      /></router-link> -->
    </div>
    <div v-else class="">
      <div class="flex items-center justify-between my-4">
        <tam-button
          class="bg-brand-black text-white rounded-xl text-[14px] px-8 hover:bg-brand-black"
          @click.prevent="router.push('/jobs?action=add')"
          color="secondary"
          label="Post a Job"
          :icon="Cross"
        />
        <router-link to="/jobs" class="font-bold cursor-pointer text-brand-black">View More</router-link>
      </div>

      <table class="w-full text-sm text-left text-gray-500 p-4">
        <thead class="uppercase bg-brand-black text-white">
          <tr>
            <th scope="col" class="px-6 py-4 text-[13px] whitespace-nowrap font-bold capitalize text-left" v-for="item in tableDetails" :key="item">
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in jobs.slice(0, 6)" :key="item.uuid" class="bg-white border-b border-light-grey hover:bg-gray-50">
            <td
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap cursor-pointer"
              @click.prevent="viewJob(item)"
            >
              <!-- :href="createJobLink(item)" -->
              <p>{{ item?.role_title }}</p>
              <div class="flex items-center mt-2">
                <span class="capitalize inline-block text-light-gray text-[13px]">{{ formatString(item?.work_type) }}</span>
                <img src="@/assets/icons/dot.svg" class="mx-2 w-[3.5px] h-[3.5px] inline-block" alt="" />
                <span class="capitalize inline-block text-light-gray text-[13px]">{{ item?.country?.name }}</span>
                <img src="@/assets/icons/dot.svg" class="mx-2 w-[3.5px] h-[3.5px] inline-block" alt="" />
                <span class="capitalize inline-block text-light-gray text-[13px]">Exp - {{ formatString(item?.level_of_experience) }}</span>
              </div>
            </td>

            <td class="px-2 py-4">
              <span
                :class="[
                  'px-4 py-1 text-[11px] rounded-md capitalize',
                  item?.status == 'open' ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-brand-danger bg-[#F8D7DA]',
                ]"
                >{{ item?.status }}</span
              >
            </td>

            <td class="px-6 py-4 text-gray-600 text-xs font-extralight">
              <div class="flex items-center">
                <img :src="item?.postedBy?.avatar_url" alt="image" class="w-6 h-6 rounded-full mr-1.5" />
                <span>{{ item?.postedBy?.name }}</span>
              </div>
            </td>

            <td class="px-6 py-4">
              <p>{{ item?.applications_count }}</p>
            </td>

            <td class="px-6 py-4 font-light">
              {{ moment(item?.created_at).format('D MMM, YYYY') }}
            </td>

            <td class="px-2 py-4 text-right">
              <div class="flex items-center flex-nowrap">
                <button v-tooltip.top="'Add candidate'" class="w-[20px] h-[20px] cursor-pointer mr-2" @click="viewJob(item)">
                  <img src="@/assets/icons/eyeline.svg" alt="" v-tooltip.top="'View job'" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="flex gap-x-4">
        <div class="border border-gray-400 rounded p-4 w-72 mb-6" v-for="job in jobs.slice(0, 5)" :key="job.uuid">
          <div class="flex justify-between items-center">
            <p class="rounded-full px-1.5" :class="job.status == 'open' ? 'text-black bg-brand-green/50' : 'text-black bg-brand-danger/50'">
              {{ job.status }}
            </p>
            <p class="rounded-full px-2 border border-brand-primary">{{ job.work_type.split('_').join(' ') }}</p>
          </div>

          <p class="font-bold text-base text-black font-cooper my-6">{{ job.job_title_name }}</p>

          <div class="flex flex-wrap gap-y-3.5 gap-x-2 mb-4">
            <p
              v-for="skill in job.skills"
              :key="skill"
              class="border rounded-full border-brand-original px-2 py-1"
              :class="[arrayOfColours[getContractClasses()]]"
            >
              {{ skill }}
            </p>
          </div>
          <p>{{ job.applications_count }} Applied</p>
        </div>
      </div> -->
    </div>
  </main>
</template>

<script setup>
import { formatString } from '@/utils/helper';
import { ref, computed, onMounted } from 'vue';
import moment from 'moment';
import TamButton from '@/components/TamButton.vue';
import { Cross } from '@/icons';
import Empty from '../components/Empty.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const tableDetails = ref(['role title', 'status', 'recruiters', 'applications', 'created date', 'action']);
const arrayOfColours = ref(['text-green-400 border-green-200', 'text-[#481e3d] border-#481e3d/20', 'text-blue-400 border-blue-200']);
const numberOfIterations = ref(6);
const pageLoaded = ref(false);
const router = useRouter();

const jobs = computed(() => {
  return store.getters['global/getAllJobs'];
});

const getContractClasses = () => {
  return Math.floor(Math.random() * numberOfIterations.value) % arrayOfColours.value.length;
  // return {
  //   'text-brand-primary': contract,
  //   'border-brand-black': contract,
  //   // style3: true, // This one is always going to be on and will have a bunch of shared styles between all of them
  // };
};

const viewJob = (detail) => {
  router.push(`/jobs/${detail.uuid}/${detail.role_title}`);
};

onMounted(async () => {
  await store.dispatch('global/getAllJobs');
  pageLoaded.value = true;
});
</script>
