<template>
  <div class="my-10">
    <div class="flex items-center justify-between mb-2">
      <p class="text-lg font-extrabold">Talents</p>
      <router-link to="/talentpool" class="font-bold cursor-pointer text-brand-black">View More</router-link>
    </div>
    <div class="table-responsive bg-white" v-if="talents.length">
      <table class="w-full text-sm text-left text-gray-500 p-4">
        <thead class="text-xs text-white font-bold bg-brand-black">
          <tr>
            <th scope="col" class="px-6 py-3 whitespace-nowrap text-left" v-for="item in tableDetails" :key="item">
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="talent in talents.slice(0, 6)" :key="talent.uuid" class="bg-white border-b hover:bg-gray-50">
            <td scope="ro" class="px-4 py-[18px] font-medium whitespace-nowrap">
              <div class="flex items-center cursor-pointer" @click.prevent="updateGeneralDialog(item)">
                <img :src="talent.avatar" alt="image" class="w-6 h-6 rounded-full mr-3" />
                <div class="flex flex-col text-xs text-[#1A1A1A]">
                  {{ talent.name }}
                  <small class="text-[10px] text-[#8F8F8F]">{{ talent.email }}</small>
                </div>
              </div>
            </td>
            <td class="px-6 py-4 text-gray-600 text-xs font-extralight inline-flex items-center">
              {{ talent?.role_title || 'N/A' }}
            </td>
            <td class="px-6 py-4">
              <!-- <span
                :class="[
                  'px-6 py-2 text-[11px] rounded-xl',
                  item?.availability_status ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-[#CB0156] bg-[#FFF0F6]',
                ]"
                >{{ item?.availability_status ? 'Available' : 'Not Available' }}</span
              > -->
              <span :class="['px-6 py-2 text-[11px] rounded-xl', 'text-[#00AD73] bg-[#E5FFF6]']">Available</span>
            </td>
            <td class="px-4 py-[18px] font-normal text-brand-black">
              {{ moment(talent.date_joined).format('D MMM YYYY') }}
            </td>
            <td class="px-6 py-4 text-right">
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                    <More />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute z-10 origin-top right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[160px]"
                  >
                    <div class="p-1 py-2">
                      <MenuItem>
                        <a href="#" @click="shareProfile(talent)" class="inline-flex items-center px-4 py-2 text-brand-black cursor-pointer">
                          <span> Share Profile</span>
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <router-link :to="`/talentpool/${talent?.uuid}`" class="inline-flex px-4 py-2 text-brand-black">
                          <span>View Talent</span>
                        </router-link>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <share-profile-dialog :open="showProfileShareDialog" @on-close="showProfileShareDialog = false" :appDetails="talentDetails" />
  <!-- <div class="my-10" v-if="pageLoaded">
    <div class="flex items-center justify-between mb-2">
      <p class="text-lg font-extrabold">Talents</p>
      <router-link to="/talentpool" class="font-bold cursor-pointer text-brand-black">View More</router-link>
    </div>
    <div v-if="talents?.length > 0" class="flex gap-x-4">
      <div class="border border-gray-400 rounded p-3 w-60" v-for="talent in talents.slice(0, 5)" :key="talent.uuid">
        <div class="flex items-center mb-3">
          <img :src="talent.avatar" class="w-10 h-10 rounded-md pr-2" />
          <p class="font-cooper  text-sm">{{ talent.name }}</p>
        </div>
        <div class="flex items-center">
          <p class="capitaliz ml- 2">{{ getCountry(talent.country)?.value }},{{ talent.state }}</p>
        </div>
        <div class="flex items-center">
          <p class="">{{ talent.professional_title_name }}</p>
        </div>
        <div class="flex items-center">
          <p class="">{{ talent.years_of_experience }} {{ talent.years_of_experience == 1 ? ' year' : ' years' }} experience</p>
        </div>
      </div>
    </div>
    <div v-else class="border border-gray-400 rounded p-3 w-60">
      <loading :is-loading="true" />
    </div>
  </div> -->
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { More } from '@/icons';
import moment from 'moment';
import ShareProfileDialog from '@/components/applications/ShareProfile';
import TamButton from '@/components/TamButton.vue';
import Location from '@/assets/icons/location.svg?inline';
import { Cross } from '@/icons';
import { useStore } from 'vuex';
import Loading from './Loading.vue';

const arrayOfColours = ref(['text-green-400 border-green-200', 'text-yellow-400 border-yellow-200', 'text-blue-400 border-blue-200']);
const showProfileShareDialog = ref(false);
const numberOfIterations = ref(6);
const store = useStore();
const pageLoaded = ref(false);
const talentDetails = ref(null);
const tableDetails = ref(['Name', 'Field', 'Availability', 'Date Joined', 'Action']);

const talents = computed(() => {
  return store.getters['global/getAllTalents'];
});

const shareProfile = (talent) => {
  talentDetails.value = talent;
  showProfileShareDialog.value = true;
};

const getCountry = (value) => {
  return store.getters['global/getCountries'].find((y) => y.key === value);
};

const getContractClasses = () => {
  return Math.floor(Math.random() * numberOfIterations.value) % arrayOfColours.value.length;
};

onMounted(async () => {
  await store.dispatch('global/getAllTalents');
  pageLoaded.value = true;
});
</script>
